<script>
import { layoutMethods, layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";
import Filters from "./filters"

export default {
  components: {
    SimpleBar,
    Filters
  },
  props: {
     boardId: {
      type: String
    }
  },
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
    };
  },
  methods: {
    ...layoutMethods,
    hide() {
      document.body.classList.remove("right-bar-enabled");
    },
    handleRightBarClick() {
      this.$parent.hideRightSidebar();
    },
    middleware(event) {
      if(!event.target || !event.target.classList)
        return false;
      return !event.target.classList.contains("toggle-right");
    },
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
    changeType(type) {
      this.changeLayoutType({ layoutType: "vertical" });
      return this.changeLeftSidebarType({ leftSidebarType: type });
    },
    changeWidth(width) {
      return this.changeLayoutWidth({ layoutWidth: width });
    },
    changeTopbartype(value) {
      return this.changeTopbar({ topbar: value });
    },
    changeloader() {
      return this.changeLoaderValue({ loader: this.loader });
    },
    /**
     * Change sidebar color change
     */
    sidebarColorChange(color) {
      return this.changeSidebarColor({
        sidebarColor: color,
      });
    },

    /**
     * Change layout position
     */
    changePosition(value) {
      return this.changeLayoutPosition({
        layoutScrollable: value,
      });
    },
  },
  computed: {
    ...layoutComputed,
    loader: {
      get() {
        return this.$store ? this.$store.state.layout.loader : {} || {};
      },
      set(value) {
        this.changeLoaderValue({
          loader: value,
        });
      },
    },
    color: {
      get() {
        return this.$store ? this.$store.state.layout.sidebarColor : {} || {};
      },
      set(value) {
        return this.changeSidebarColor({
          sidebarColor: value,
        });
      },
    },
    sidebarType: {
      get() {
        return this.$store
          ? this.$store.state.layout.leftSidebarType
          : {} || {};
      },
      set(value) {
        return this.changeLeftSidebarType({
          leftSidebarType: value,
        });
      },
    },
    scrollable: {
      get() {
        return this.$store
          ? this.$store.state.layout.layoutScrollable
          : {} || {};
      },
      set(value) {
        return this.changeLayoutPosition({
          layoutScrollable: value,
        });
      },
    },
    layout: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
      set(value) {
        this.changeLayoutType({
          layoutType: value,
        });
        if (value === "horizontal") {
          this.changeTopbar({
            topbar: "dark",
          });
        } else if (value === "vertical") {
          this.changeTopbar({
            topbar: "light",
          });
        }
      },
    },
    topbar: {
      get() {
        return this.$store ? this.$store.state.layout.topbar : {} || {};
      },
      set(value) {
        this.changeTopbar({
          topbar: value,
        });
      },
    },
    mode: {
      get() {
        return this.$store ? this.$store.state.layout.layoutMode : {} || {};
      },
      set(value) {
        if (value === "dark") {
          document.body.setAttribute("data-layout-mode", "dark");
          this.changeTopbar({
            topbar: "dark",
          });

          this.changeSidebarColor({
            sidebarColor: "dark",
          });
        } else {
          document.body.setAttribute("data-layout-mode", "light");
          this.changeTopbar({
            topbar: "light",
          });
        }
      },
    },
    width: {
      get() {
        return this.$store ? this.$store.state.layout.layoutWidth : {} || {};
      },
      set(value) {
        this.changeLayoutWidth({
          layoutWidth: value,
        });
      },
    },
  },
  watch: {
    mode: {
      immediate: true,
      handler: function (newVal) {
        switch (newVal) {
          case "light":
            document.body.setAttribute("data-layout-mode", "light");

            break;
          case "dark":
            document.body.setAttribute("data-layout-mode", "dark");

            break;
        }
      },
    },
    topbar: {
      immediate: true,
      handler: function (newVal) {
        switch (newVal) {
          case "light":
            document.body.setAttribute("data-topbar", "light");
            break;
          case "dark":
            document.body.setAttribute("data-topbar", "dark");
            break;
        }
      },
    },
  },
};
</script>

<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <SimpleBar class="h-100">
        <div class="rightbar-title d-flex align-items-center bg-dark p-3">
          <h5 class="m-0 me-2 text-white"> {{ $t('common.filters') }}</h5>

          <a href="javascript:void(0);" class="right-bar-toggle-close ms-auto" @click="hide">
            <i class="mdi mdi-close noti-icon"></i>
          </a>
        </div>

        <!-- Settings -->
        <hr class="m-0" />

       <div class="ps-3 pe-3">
          <filters :board-id="boardId">

          </filters> 
        </div>
      </SimpleBar>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>

    <!-- chat offcanvas -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasActivity"
      aria-labelledby="offcanvasActivityLabel"
    >
      <div class="offcanvas-header border-bottom">
        <h5 id="offcanvasActivityLabel"></h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">...</div>
    </div>
  </div>
</template>
