<script>
import { mapGetters, mapActions } from 'vuex';
import Tags from '@/components/tags';

export default {
  components: {
    Tags
  },
   props: {
    boardId: {
      type: String
    }
  },
  computed :{
    ...mapGetters('boardView', {
      views: 'views'
    }),
    ...mapGetters('boards', {
      boards: 'items'
    }),
    ...mapGetters('directoryItems', {
      allTags: 'tags'
    }),
    view() {
      return this.views(this.boardId);
    },
     board() {
      return this.boards.find(x => x.id === this.boardId);
    },
    keyword:
    {
      get: function() {
        return this.view.keyword || "";
      },
      set: function(newValue) {
       this.setKeyword({id: this.boardId, value: newValue });
      }
    },
     tags() {
      if(!this.allTags || !this.board) return [];
      let boardDirectoryIDs = [];
      this.board.lanes.forEach(lane => {
        boardDirectoryIDs.push(lane.directoryId);
      });
      boardDirectoryIDs = [...new Set(boardDirectoryIDs)];
      let tags = [];
      boardDirectoryIDs.forEach(dirtId => {
        if(this.allTags[dirtId] && this.allTags[dirtId].length > 0) {
          tags = [...this.allTags[dirtId]];
        }
      });
      tags = [...new Set(tags)];
      return tags;
    }, 
    selectedTags:
    {
      get: function() {
        return this.view.tags || [];
      },
      set: function(newValue) {
        this.setTags({id: this.boardId, value: newValue });
      }
    }, 
  },
  methods:{
    ...mapActions('boardView', {
      setTags: 'setTags',
      setKeyword: 'setKeyword'
    })
  }
};
</script>

<template>
  <div>
    <div class="mt-3 mb-2 bg-light border p-2" >
      <div class="">
        <div class="text-center text-dark mb-1"> <span>{{$t('common.keyword')}}</span></div>
        <input type="text" 
          v-model="keyword"
          class="form-control"
          :placeholder="$t('common.keyword')"     
        />
        <small class="text-muted">{{$t('common.searchByNumberHelpText')}}</small>
      </div>
    </div>
    <div class="mt-2 mb-2">
      <tags :tags="tags" :value="selectedTags" @update="tags => selectedTags = tags" :editable="true"></tags>
    </div>
  </div>
</template>
