<script>

import {  /*mapGetters, ,*/ mapMutations, mapActions } from 'vuex';
import draggable from 'vuedraggable'
import LaneItem from './lane-item.vue'
import Swal from 'sweetalert2'
//import { throttle } from "lodash";
import { isCustomFieldEmpty } from '@/helpers'

export default {
  components: {
    draggable,
    LaneItem
  },
  props: {
    boardId: {
      type: String,
      required: true
    },
    directory: {
      type: Object,
      required: true
    },
    status: {
      type: Object,
      required: true
    },
    orderByField: {
      type: String,
      required: true
    },
    data:{
      type: Object,
      required: true
    },
    collapsed:{
      type: Boolean,
    },
    selectedItemId: {
      type: String,
    }
  },
  data() {
    return {
      dragged: null,
      draggedIndex: null
    };
  },
  computed: {
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      console.log('navigator.userAgent', navigator.userAgent);
      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      })
    },
    total () {
      return this.data.total || 0;
    },
    items:  {
      get: function() {
        return this.data.items || [];
      },
      set: async function(newValue) {
        this.updateItems(newValue);          
      }
    },
    group() {
     return { 
      name: this.directory.id,
      pull: true,
      put(to, from) {
        if(to.options.group.name !== from.options.group.name) {
          return false;
        }
        return true;
      }};
    }
  },
  
  methods:{
    ...mapMutations('boardView', {
       updateStatusItems: 'UPDATE_STATUS_ITEMS'
    }),
     ...mapActions('boardView', {
       more: 'more',
       move: 'move',
       load: 'reloadBoard'
    }),

    
    // ...mapActions('directoryItems', {
    //    move: 'move',
    //    more: 'more',
    //    load: 'load'
    // }),
    // scrollX: throttle(function({scrollableElement, scrollTo}){
    //   scrollableElement.scrollLeft = scrollTo;
    // }, 500),
    // scrollY: throttle(function({scrollableElement, scrollTo}){
    //    scrollableElement.scrollTop = scrollTo;
    // }, 500),
    onDrag(/*evt*/) {
    
    },
    onStart(evt) {
      console.log('onStart', evt);
      this.dragged = this.items[evt.oldIndex];
      this.draggedIndex = evt.oldIndex;

      // const from = evt.from;
      // let scrollableElement = from.parentElement;

      // while(scrollableElement ){
      //   if(scrollableElement.classList.contains("board-scrolable-container")) {
      //     break;
      //   }
      //   scrollableElement = scrollableElement.parentElement;
      // }
      // if(scrollableElement) {
      //   this.originalMousePosX = evt.originalEvent.clientX;
      //   this.originalMousePosY = evt.originalEvent.clientY;
      //   this.scrollableElement = scrollableElement;
      //   this.scrollToX = null;
      //   this.scrollToY = null;
      //   this.draggedItem = evt.item;
      //   scrollableElement.addEventListener('drag', this.onDrag);  
      // }
    },
    onMove(evt) {
      console.log('onMove', evt);
    },
    onAdd(evt) {
      console.log('onAdd', evt);
    },
    onEnd(evt) {
      console.log('onEnd', evt);
      this.dragged = null;
      this.draggedIndex = null;
      this.$emit('dragEnd');
      

      // if(this.scrollableElement) {
      //   this.scrollableElement.removeEventListener('drag', this.onDrag);
      //   this.scrollableElement = null;
      //   this.draggedItem = null;
      // }
    },
    async updateItems(newValue){
        console.log('updateItems');
      const oldValue = this.items;

      if(this.orderByField !== '$$status-pos$$' && oldValue.length === newValue.length) {
        return;
      }

      if(oldValue.length > newValue.length) {
        this.updateStatusItems({ id: this.boardId, status: this.status.id, items: newValue, total: this.total - 1});
        return;
      }

      let prev = null;
      let item = this.dragged;
      if(newValue.length > oldValue.length) {
      
        let o = new Set(oldValue);
        item = newValue.find(x => !o.has(x));
        if(item) {
          const pos = newValue.indexOf(item);
          if(pos > 0 ) {
            prev = newValue[pos-1];
          }
        }
      } else {
        const newIndex = newValue.indexOf(this.dragged);
        const oldIndex = oldValue.indexOf(this.dragged);
        if(newIndex <= oldIndex) {
          const olditem = newIndex > 0 ? oldValue[newIndex-1] : null;
          prev = olditem;
        }else {
          const olditem = newIndex > 0 ? oldValue[newIndex] : null;
          prev = olditem;
        }
      }

      if(item) {
       
        var prevStatus = this.directory.statusList.find(x => x.id === item.status);
        var nextStatus =  this.status;

        var statusRequiredFields = nextStatus.fields.filter(x => x.required);
        let allow = true;
        statusRequiredFields.forEach(async reqField  => {
          if(!allow) return;

          const fieldDef = this.directory.fields.find(x => x.id === reqField.field);
          if(!fieldDef) return;

          const f = item.customFields[reqField.field];
          if(!f || isCustomFieldEmpty(f)) {
            Swal.fire({
              text: this.$t('common.field') + ' ' + fieldDef.name + ' ' + this.$t('common.isRequired'),
              icon: "warning",
            });
            allow = false;
            return;
          }
        });

        if(!allow) {
          this.load({id: this.boardId});
          return;
        }

        var noteRequired = false;
        if(this.directory.commentsEnabled && (prevStatus.requiredCommentToLeave || nextStatus.requiredCommentToEnter)) {
          noteRequired = true;
        }
        this.updateStatusItems({ id: this.boardId, status: this.status.id, items: newValue, total: item.status === this.status.id ?  this.total : (this.total + 1)});
        let note = null;
        if(noteRequired) {
          const { value } = await Swal.fire({
            title: this.$t('common.commentRequired'),
            input: 'text',
            inputLabel: this.$t('common.pleaseWriteComment'),
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return this.$t('common.youNeedToWriteSomething')
              }
            }
          });
          note = value;
        }
       
        if (note || !noteRequired) {
          this.move({id: this.boardId, prevId: (prev || {}).id, itemId: item.id, status: this.status.id, ignorePosition: this.orderByField !== '$$status-pos$$',  note});
        } else {
          this.load({id: this.boardId});
        }
      }
    },
    startHold(id) {
      this.$emit('hold', id);
    }
  }
};
</script>

<style scoped>
.status {
  min-width: 300px;
  width: 300px;
  max-width: 300px; 
  
}
.status.body {
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  border-right: 3px solid white;

  min-height: 300px;
 
}

.status.body.default {
   min-height: 300px;
   
}

.status.body.user-defined {
  position: relative;
}

.status.body:first-child {
  border-left: 3px solid white;
}

.list-container {
  min-height: 300px;
}


table.board-table > tbody > tr > td {
  vertical-align: top;
}

.status.collapsed {
  min-width: 50px;
  width: 50px;
  max-width: 50px; 
}
  
</style>

<template>

  <draggable 
    v-model="items" 
    tag="td" 
    item-key="index" 
    :group="group" 
    @start="onStart" 
    @end="onEnd" 
    @add="onAdd"
    @move="onMove"
     :delay="isMobile ? 400 : 0"
    handle=".drag-area"
    class="status body bg-light text-center my-draggable-div"
    :class="{'collapsed': collapsed}"
   
  >
    <template  #header>
     
    </template>
    
    <template #item="{element}">
      <lane-item 
        :selected-item-id="dragged ? dragged.id : null"
        :item="element" 
        :directory="directory" 
        :status="status" 
        :board-id="boardId" 
        v-if="!collapsed"
        @hold="startHold"
      >
      </lane-item>
    </template>

    <template  #footer>
      <div v-if="total > items.length && !collapsed" class="btn btn-link d-block" @click="more({id: boardId, statusId: status.id})">
        <i class="mdi-chevron-double-down mdi font-size-20"></i>
      </div>
    </template>
  </draggable>
</template>
