<script>
import Layout from "../../layouts/main";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import LaneHeader from './components/lane-header';
import Lane from './components/lane';
import Tags from '@/components/tags';
import FilterRightSidebar from './filter-right-sidebar';

//import draggable from 'vuedraggable'
//import Swal from 'sweetalert2'

export default {
  components: {
    Layout,
    Tags,
    LaneHeader,
    Lane,
    FilterRightSidebar
    // draggable
  },
  data() {
    return {
      initializing: false,
      inited: false,
      selectedItemId: null,
      dragging: false,
      keyword: ''
    };
  },
  props:{
    id: {
      type: String,
      required: true
    }
  },
  created() {
    document.title = this.pagetitle;
    this.init();
  },
  computed :{
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      console.log('navigator.userAgent', navigator.userAgent);
      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      })
    },
    pagetitle () {
      return this.board ? this.board.name : 'Loading...';
    },  
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs',
    }),
     ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('boards', {
      boards: 'items',
      deleting: 'deleting'
    }),
    ...mapGetters('directoryItems', {
      allTags: 'tags',
      changed: 'changed'
    }),
    ...mapGetters('boardView', {
      views: 'views'
    }),
    ...mapGetters('boot', {
      appReady: 'ready'
    }),
    view() {
      return this.views(this.id);
    },
    loading() {
      return this.view.loading || false;
    },
    board() {
      return this.boards.find(x => x.id === this.id) || {lanes: []};
    },
    tags() {
      if(!this.allTags || !this.board) return [];
      let boardDirectoryIDs = [];
      this.board.lanes.forEach(lane => {
        boardDirectoryIDs.push(lane.directoryId);
      });
      boardDirectoryIDs = [...new Set(boardDirectoryIDs)];
      let tags = [];
      boardDirectoryIDs.forEach(dirtId => {
        if(this.allTags[dirtId] && this.allTags[dirtId].length > 0) {
          tags = [...this.allTags[dirtId]];
        }
      });
      tags = [...new Set(tags)];
      return tags;
    }, 
    selectedTags:
    {
      get: function() {
        return this.view.tags || [];
      },
      set: function(newValue) {
        this.setTags({id: this.id, value: newValue });
      }
    }, 
    // keyword:
    // {
    //   get: function() {
    //     return this.view.keyword || "";
    //   },
    //   set: function(newValue) {
    //    this.setKeyword({id: this.id, value: newValue });
    //   }
    // },
    orderByField:
    {
      get: function() {
        return this.view.orderBy.field;
      },
      set: function(newValue) {
        this.setOrderByField({id: this.id, value: newValue });
      }
    },
    orderByAsc:
    {
      get: function() {
        return this.view.orderBy.asc;
      },
      set: function(newValue) {
        this.setOrderByAsc({id: this.id, value: newValue });
      }
    },
    isBusy() {
      return this.initializing || this.loading;
    },
    lanes() {
      let res = [];
      const viewData = this.view.data;
      const collapsedLanes = this.view.collapsed;
      this.board.lanes.forEach(lane => {
        const directory = this.directories.find(x => x.id === lane.directoryId);
        if(!directory) {
          return;
        }
        const status = directory.statusList.find(x => x.id === lane.statusId);
         if(!directory) {
          return status;
        }
        const data = viewData[status.id] || { total: 0, items: [] };
        const collapsed = collapsedLanes[status.id] || false;
        res.push({ directory, status, data, collapsed });
      });
      return res;
    },
    allowNewDirectories() {
     
      let boardDirectoryIDs = [];
      this.board.lanes.forEach(lane => {
        let allow = false;
        if(this.currentUser.isAdmin ) {
          allow = true; 
        } else {
          if(!this.currentUsageProfile) {
            allow = false;
          } else {
            const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === lane.directoryId);
            if(!dirAccess) {
               allow = false;
            } else {
              allow = dirAccess.allowCreate;
            }
          }
        }    
        if(allow) {
          boardDirectoryIDs.push(lane.directoryId);
        }
        
      });
      boardDirectoryIDs = [...new Set(boardDirectoryIDs)];
      const res = boardDirectoryIDs.map(id => this.directories.find(a => a.id === id)).filter(x => x);
      return res;
    }
  },
  watch: {
    changed (newValue, oldValue) {
      if(newValue !== oldValue) {
        this.debounceReloadBoard({ id: this.id });
      }
    },
    appReady (newValue, oldValue) {
      if(newValue !== oldValue) {
        this.init();
      }
    },
    pagetitle(newValue, oldValue) {
      if(newValue !== oldValue) {
        document.title = this.pagetitle;
      }
    }
  },
  methods: {
    ...mapMutations('directoryItems', {
      openItem: 'OPEN_ITEM'
    }),
    ...mapActions('boardView', {
      debounceReloadBoard: 'debounceReloadBoard',
      initView: 'init',
      setTags: 'setTags',
      setKeyword: 'setKeyword',
      collapseLane: 'collapseLane',
      expandLane: 'expandLane',
      reloadBoard: 'reloadBoard',
      setOrderByField: 'setOrderByField',
      setOrderByAsc: 'setOrderByAsc'
    }),
    async init() {
      if(!this.appReady) return;

      if(this.initializing || this.inited) {
        return;
      }

      this.initializing = true;
      
      await this.initView({ id: this.id });

      this.keyword = this.view.keyword || "";

      this.inited = true;
      this.initializing = false;
    },
    collapse(statusId) {
      this.collapseLane( { id: this.id, statusId});
    },
    expand(statusId) {
      this.expandLane({ id: this.id, statusId});
    },
     toggleFilter () {
      document.body.classList.toggle("right-bar-enabled");
    },
     newItem(dir) {
      this.openItem ( { item: { directoryId: dir.id }, mode:'edit'});
    },
    endHold() {
      //console.log("endHold");
      if(this.holdTimer) {
        clearTimeout(this.holdTimer);
        this.holdTimer = null;
      }
      this.selectedItemId = null;
      this.dragging = false;
      this.dragScrollMove = null;
    },
    startHold(id) {
      this.dragScrollMove = null;
      //console.log("startHold", id);
      if(id) {
        this.selectedItemId = id;
       
        const t = setTimeout(() =>{
          if(this.selectedItemId) {
            const moving = this.dragScrollMove || {deltaX: 0, deltaY: 0};
            console.log("start dragiing: ",{moving} );
            if(Math.abs(moving.deltaX) < 100 && Math.abs(moving.deltaY) < 100) {
              this.dragging = true;
              console.log("start dragiingYYYY: ",{moving} );
            }
          }
        }, 1000);
        this.holdTimer = t;
      } else {
        this.endHold();
      }
    },
    onDragScrollMove(details) {
     
      if(!this.dragScrollMove) {
        this.dragScrollMove = details;
      } else {
        this.dragScrollMove = {
          deltaX: this.dragScrollMove.deltaX + details.deltaX,
          deltaY: this.dragScrollMove.deltaY + details.deltaY,
        }
      }
      
    },
    search() {
      this.setKeyword({id: this.id, value: this.keyword });
    }
  }
};
</script>


<style scoped lang="scss">
#board-view-container{
  top:50px;
  left:0;
  bottom: 0; 
  right:0; 
  //overflow: auto;

  @media (min-width: 992px) {
    top:70px;
  }

  .board{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    
    .board-toolbar {
      margin-top: 10px;
      @media (min-width: 992px) {
          margin-top: 0;
        }

    }
    .board-content{
      align-self: stretch;
      flex:1;
      position: relative;
      overflow: auto;
    }

    .board-scrolable-container{
      height: 100%;
      overflow: auto;

      table {
        height: 100%;
        margin: 0;
      }
    }
  }
}
</style>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="isBusy">
    
    <div class="position-absolute" id="board-view-container" >
      <div class="board">
        <div class="board-toolbar mx-3">
          <div class="row">
            <div class="col-auto">
              <div class="mb-2 d-none d-md-inline-block" style=" min-width:150px">
                <div class="input-group">
                  <input class="form-control" :placeholder="$t('common.search')" v-model="keyword" v-on:keyup.enter="search"/>
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></button>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="col-auto" style="position:relative;">
              <div class="d-none d-md-inline-block">
                <tags :tags="tags" :value="selectedTags" @update="tags => selectedTags = tags" :editable="true" :add-tag-text="$t('common.searchByTag')"></tags>
              </div>
            </div>

            <div class="col text-end">
              <div class="ms-2 mb-2  d-inline-block" v-if="allowNewDirectories.length > 0">
                <div class="dropdown d-inline-block">
                  <button
                    type="button"
                    class="btn btn-outline-secondary waves-effect text-dark"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus" />
                    <div class="text-sm-end d-none d-lg-inline-block ms-2">
                      {{ $t('common.newRecord') }}
                    </div>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end pt-2">
                    <a 
                      href="javascript:void(0)"
                      class="dropdown-item text-nowrap" 
                      v-for="dir in allowNewDirectories" :key="dir.id"
                      @click="newItem(dir)"
                    >
                      <font-awesome-icon icon="fa-solid fa-plus" />
                      <span class="ms-2">
                        {{ dir.name }}
                      </span>
                    </a>
                  </div>
                </div>
                <!-- <button
                  type="button"
                  class="btn btn-primary"
                  @click="newItem"
                  > 
                  <font-awesome-icon icon="fa-solid fa-plus" />
                  <div class="text-sm-end d-none d-lg-inline-block ms-2">
                    {{ $t('common.newRecord') }}
                  </div>
                </button> -->
              </div>
              <div class=" ms-2 mb-2  d-inline-block">
                <div class="dropdown d-inline-block">
                  <button
                      type="button"
                      class="btn btn-outline-secondary waves-effect text-dark"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                      <div class="text-sm-end d-none d-lg-inline-block">
                        <span class="me-2" v-if="orderByField === '$$status-pos$$'">
                          {{$t('common.manual')}}
                        </span>
                        <span v-if="orderByField === 'Number'">{{$t('directory.fields.number')}}</span>
                        <span v-if="orderByField === '$$modified-at$$'">{{$t('common.sortingByModification')}}</span>
                      </div>
                      <span class="ms-2">
                        <font-awesome-icon icon="fa-solid fa-arrow-down-a-z" v-if="orderByAsc" />
                        <font-awesome-icon icon="fa-solid fa-arrow-up-z-a" v-if="!orderByAsc"/>
                        <font-awesome-icon icon="fa-solid fa-caret-down" class="ms-1"/>
                      </span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end pt-2">
                    <a class="dropdown-item" :class="{'active': orderByField === '$$status-pos$$'}" href="javascript:void(0)" @click="orderByField = '$$status-pos$$'">
                      <font-awesome-icon icon="fa-regular fa-square-check" v-if="orderByField === '$$status-pos$$'"/>
                      <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="orderByField !== '$$status-pos$$'" />
                      <span class="ms-2"> {{$t('common.manual')}}</span>
                    </a>
                    <a class="dropdown-item" :class="{'active': orderByField === 'Number'}" href="javascript:void(0)"  @click="orderByField = 'Number'">
                      <font-awesome-icon icon="fa-regular fa-square-check" v-if="orderByField === 'Number'"/>
                      <font-awesome-icon icon="fa-regular fa-square" class="text-muted" v-if="orderByField !== 'Number'" />
                      <span class="ms-2">{{$t('directory.fields.number')}}</span>
                    </a>
                    <a class="dropdown-item" :class="{'active': orderByField === '$$modified-at$$'}" href="javascript:void(0)"  @click="orderByField = '$$modified-at$$'">
                      <font-awesome-icon icon="fa-regular fa-square-check" v-if="orderByField === '$$modified-at$$'"/>
                      <font-awesome-icon icon="fa-regular fa-square" class="text-muted" v-if="orderByField !== '$$modified-at$$'" />
                      <span class="ms-2">{{$t('common.sortingByModification')}}</span>
                    </a>
                   
                    <hr v-if="orderByField !== '$$status-pos$$'"/>
                    <div class="text-center mb-2" v-if="orderByField !== '$$status-pos$$'"> 
                      <div class="btn-group" role="group">
                        <button class="btn btn-sm" :class="{'btn-outline-primary': !orderByAsc, 'btn-primary': orderByAsc}" @click="orderByAsc =  true">
                          <font-awesome-icon icon="fa-solid fa-arrow-down-a-z" class="me-2"  />
                          Asc
                        </button>
                        <button class="btn btn-sm" :class="{'btn-outline-primary': orderByAsc, 'btn-primary': !orderByAsc}" @click="orderByAsc =  false">
                          <font-awesome-icon icon="fa-solid fa-arrow-up-z-a" class="me-2"  />
                          Desc
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ms-2 mb-2 d-inline-block d-lg-none d-md-none" >
                <div class="d-inline-block">
                  <button
                    type="button"
                    class="btn btn-outline-secondary waves-effect text-dark"
                    
                    @click.stop.prevent="toggleFilter(slotProps)"
                  >
                    <font-awesome-icon icon="fa-solid fa-filter"/>
                    <div class="text-sm-end d-none d-lg-inline-block ms-2">
                        {{ $t('common.filters') }}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="board-content" @mouseup="endHold">
          
          <div class="board-scrolable-container" 
            
          >
            <table class="table project-list-table table-nowrap align-middle table-borderless board-table" style=" position: relative;border-spacing: 0" v-if="inited" >
              <thead>
                <tr>
                  <lane-header 
                    v-for="(lane, index) in lanes" 
                    :status="lane.status" 
                    :directory="lane.directory"
                    :data="lane.data"
                    :key="index"
                    allow-collapse
                    :collapsed="lane.collapsed"
                    @collapse="() => collapse(lane.status.id)"
                    @expand="() => expand(lane.status.id)"
                    
                   
                  >
                  </lane-header>
                  
                  <th style=" position: sticky; top: 0;z-index: 1;background: white;">

                  </th>
                </tr>
              </thead>
              
              <tbody>
                <tr>
                  <template 
                    v-for="(lane, index) in lanes" 
                     :key="index"
                  >
                  
                    <lane 
                    
                      :status="lane.status" 
                      :directory="lane.directory"
                      :order-by-field="orderByField"
                      :collapsed="lane.collapsed"
                      :data="lane.data"
                      :board-id="id"
                      @hold="startHold"
                      :selected-item-id="dragging ? selectedItemId : null"
                      @dragEnd="endHold"
                    >
                    </lane>
                  
                  </template>
                  <td  class=" bg-light" style="border-top: 3px solid white;border-bottom: 3px solid white;border-right: 3px solid white;">
                  </td>
                </tr>
              </tbody> 
            </table>
          </div>
        </div>
        <!-- 
        <div class="lanes">

        </div> -->
      </div>
      
    
    </div>

    <filter-right-sidebar v-if="inited" :board-id="id">
    </filter-right-sidebar>
  </Layout>
</template>
