<script>

import {  mapGetters, mapMutations, mapActions } from 'vuex';
import CustomField from '../../../../components/custom-fields/custom-field.vue';
import StatusColor from '../../directories/status-color.vue';
import Swal from 'sweetalert2'
import Tags from '@/components/tags'
import { isCustomFieldEmpty } from '@/helpers'

export default {
  components: {
    CustomField,
    StatusColor,
    Tags
},
  props: {
    boardId: {
      type: String,
      required: true
    },
    directory: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    status: {
      type: Object,
      required: true
    },
    selectedItemId: {
      type: String,
    }
  },
  data() {
    return {
      
    };
  },
  computed : {
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs',
    }),
     ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
   fields() {
    return this.directory.fields.filter(x => x.visibleOnBoard && !x.hidden);
   },
   colorComputedStyle() {
    if(this.directory.type === 'Process') {
        const status = this.directory.statusList.find(x => x.id === this.item.status);
        if(status && status.highlightEntireRecord) {
          return {'color': status.fgColor};
        }
      }
      return null;
   },
   computedStyle() {
      if(this.directory.type === 'Process') {
        const status = this.directory.statusList.find(x => x.id === this.item.status);
        if(status && status.highlightEntireRecord) {
          return {'background-color': status.color, 'color': status.fgColor || 'inherit'};
        }
      }
      return null;
    }
  },
  methods:{
    ...mapMutations('directoryItems', {
       openItem: 'OPEN_ITEM'
    }),
     ...mapActions('boardView', {
       changeStatus: 'changeStatus',
       reloadBoard: 'reloadBoard'
    }),
    onLoad() {
      this.reloadBoard({id: this.boardId});
    },
    async setStatus(status) {
      if(status !== this.status.id) {

        var statusRequiredFields = status.fields.filter(x => x.required);
        let allow = true;
        statusRequiredFields.forEach(async reqField  => {
          if(!allow) return;
          const fieldDef = this.directory.fields.find(x => x.id === reqField.field);
          if(!fieldDef) return;

          const f = this.item.customFields[reqField.field];
          if(!f || isCustomFieldEmpty(f)) {
            Swal.fire({
              text: this.$t('common.field') + ' ' + fieldDef.name + ' ' + this.$t('common.isRequired'),
              icon: "warning",
            });
            allow = false;
            return;
          }
        });

        if(!allow){
          return;
        }

        var noteRequired = false;
       
        var prevStatus = this.directory.statusList.find(x => x.id === this.item.status);
        var nextStatus =  status;
        if(this.directory.commentsEnabled && (prevStatus.requiredCommentToLeave || nextStatus.requiredCommentToEnter)) {
          noteRequired = true;
        }
        let note = null;
        if(noteRequired) {
          const { value } = await Swal.fire({
            title: this.$t('common.commentRequired'),
            input: 'text',
            inputLabel: this.$t('common.pleaseWriteComment'),
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return this.$t('common.youNeedToWriteSomething')
              }
            }
          });
          note = value;
        }
        if (note || !noteRequired) {
          const item = JSON.parse(JSON.stringify(this.item));
          item.status = status.id;
          if(note) {
            item.lastNote = note;
          }
          this.changeStatus({ id: this.boardId, directoryId:this.directory.id, item, status: status.id, prevStatus: this.status.id, note});
        }
      }
    },
    getRelatedFieldDef(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.directory.fields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const directoryRelatedField = directory.fields.find(x => x.id === directoryRelatedFieldId);
          return directoryRelatedField;
        }
      }
      return null;
    },
    getRelatedFieldValue(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.directory.fields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const dirValue = this.item.customFields[directoryFieldId];
           
          if(dirValue) {
            return dirValue.customFields[directoryRelatedFieldId];
          }
        }
      }
      return null;
    },
    isFieldHidden(field) {
    
    
      if(field.hidden) return true;

      let hidden = false;

      if(this.directory.type === 'Process' && this.status) {
         var statusField = this.status.fields.find(x => x.field === field.id);
         if(statusField) {
          hidden = statusField.hidden;
         }
      }
     
      if(hidden) return true;


      if(this.currentUser.isAdmin)
        return false;
        
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directory.id);
       
      if(!dirAccess) {
        return false;
      }

     
      return dirAccess.hiddenFields && dirAccess.hiddenFields.indexOf(field.id) >= 0;
    },
    startHold() {
      this.$emit('hold', this.item.id);
    }
  }
};
</script>

<style scoped>
.status-button{
  max-width:190px;
}

.highlighted-card{
  outline: 3px solid;
  box-shadow: 3px;
}
</style>

<template>
  <div >
    <div 
      class="card text-start drag-area mb-2" 
      :style="computedStyle" 
      @mousedown="startHold"
      :class="{'highlighted-card': selectedItemId === item.id}" 
    >
      <div class="card-body p-2 pb-0">
        <div class="row mb-2">
          <u class="col ">
            <a href="javascript:void(0)" @click="openItem({item, mode: 'view'})" :style="computedStyle">
               <b class="d-inline-block text-decoration-underline">{{directory.name}} #{{item.number}}</b>
            </a>
            
          </u>
         
        </div>
        <div class="mb-2">
          <tags :tags="[]" :value="item.tags"></tags>
        </div>
        <div class="row mb-2">
          <div class="col ">
            <div class="dropdown d-inline-block ">
              <button
                type="button"
                class="btn btn-light btn-sm text-nowrap text-truncate status-button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style=""
              >
                <status-color :color="status.color"></status-color>
                {{status.name}} 
                
                <i class="fa-caret-down fa ms-1"></i>
              </button>
                
              <div class="dropdown-menu dropdown-menu-end ">
                <a class="dropdown-item" 
                  v-for="ps in directory.statusList" :key="ps.id"
                  href="javascript:void(0)"  
                  @click="this.setStatus(ps)"
                >
                   <status-color :color="ps.color"></status-color>
                  {{ps.name}} 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-2 pt-0" 
      >
        <div  v-if="directory.fields.length > 0 && Object.keys(item.customFields).length > 0"> 
          <template v-for="field in fields.filter(x => !isFieldHidden(x))" :key="field.id">
            <template v-if="field.type === 'DirectoryRelatedField' && getRelatedFieldValue(field.directoryFieldId, field.directoryRelatedFieldId)">
              <div class="mb-1" :title="field.name" >
                <label class="m-0 dir-item-field-label">{{field.name}}</label>
                <div class="text-wrap">
                  <custom-field 
                    :def="getRelatedFieldDef(field.directoryFieldId, field.directoryRelatedFieldId)" 
                    :value="getRelatedFieldValue(field.directoryFieldId, field.directoryRelatedFieldId)" 
                     :board="true"
                  >
                  </custom-field>
                </div>
              </div>
            </template>
            <template v-if="field.type !== 'DirectoryRelatedField'">
              <div  class="mb-1" v-if="item.customFields[field.id]">
                <label class="m-0 dir-item-field-label">{{field.name}}</label>
                <div class="text-wrap">
                  <custom-field 
                    :def="field" 
                    :value="item.customFields[field.id]"
                    :board="true"
                  >
                  </custom-field>
                </div>
              </div>
            </template>
            
          </template>
        </div>
        
      </div>
      <hr v-if="item.lastNote" class="mb-2 mt-0"/>
      <div v-if="item.lastNote" class="card-body text-wrap p-2" >
          <label class="m-0 dir-item-field-label">{{$t('directory.fields.lastNote')}}</label>  
          <div class="text-wrap">
            {{item.lastNote}}
          </div>
      </div>
    </div>
  </div>
</template>
