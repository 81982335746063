<script>

//import {  mapGetters } from 'vuex';
import StatusColor from '../../directories/status-color.vue';

export default {
  components: {
    StatusColor
},
  props: {
    directory: {
      type: Object,
      required: true
    },
    status: {
      type: Object,
      required: true
    },
    data:{
      type: Object,
      required: true
    },
    collapsed:{
      type: Boolean,
    },
    allowCollapse:{
      type: Boolean,
      required: false
    }
    // keywordEnabled: {
    //   type: Boolean,
    //   required: false
    // },
    // sortingEnabled: {
    //   type: Boolean,
    //   required: false
    // },
  },
  computed: {
    // ...mapGetters('directoryItems', {
    //   allItems: 'itemsData',
    // }),
    // dirItems() {
    //   return this.allItems[this.directory.id] || { board: { statusItems: {} }};
    // },
    // statusItems() {
    //   return (this.dirItems.board.statusItems || {})[this.status.id] || { total: 0, items: []};
    // },
    total () {
      return this.data.total || 0;
    },
    
  },
  methods: {
      collapse() {
        this.$emit('collapse');
      },
      expand() {
        this.$emit('expand');
      }
    }
};
</script>

<style scoped>
.status-header {
  min-width: 300px;
  width: 300px;
  max-width: 300px; 

}

.status-header.collapsed {
  min-width: 50px;
  width: 50px;
  max-width: 50px; 
}

.status-header-with-pe{
  padding-right: 30px;;
}

</style>

<template>
  <th 
    style=" position: sticky; top: 0;z-index: 1;background: white;"
    class="status-header border"
    :class="{'collapsed': collapsed}"
  >
    <div class="{'status-header-with-pe': allowCollapse">
      <div class=" overflow-hidden" style="padding-right:30px;" :title="status.name">
        <div class="text-start text-truncate">
          <b class="me-2" v-if="!collapsed && (!status.countLimit || status.countLimit === 0)">[{{total}}]</b>
          <b class="me-2" v-if="!collapsed && (status.countLimit > 0)" :class="{'text-danger font-size-16': status.countLimit <= total}">
            [{{total}}/{{status.countLimit}}]
          </b>
          <status-color :color="status.color"></status-color> {{status.name}}
        
        </div>
      </div>
    
      <div style="position: absolute; top: 8px; right:12px">
        <button class="btn btn-sm btn-light" v-if="allowCollapse && !collapsed" @click="collapse">
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </button>
        <button class="btn btn-sm btn-light" v-if="allowCollapse  && collapsed" @click="expand">
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </button>
        <!-- <div class="dropdown" >
          <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><h5 class="dropdown-header"><b>View</b></h5></li>
            <li>
              <a class="dropdown-item" :class="{'active': viewMode === 'compact'}" href="javascript:void(0)" @click="setStateViewMode({stateId: state.id, mode: 'compact'})">
                <i class="mdi mdi-check me-1" v-if="viewMode === 'compact'"></i>
                <i class="mdi mdi-checkbox-blank-circle-outline me-1"  v-if="viewMode !== 'compact'"></i>
                Compact
              </a>
            </li>
            <li>
              <a class="dropdown-item" :class="{'active': viewMode === 'default'}" href="javascript:void(0)" @click="setStateViewMode({stateId: state.id, mode: 'default'})">
                <i class="mdi mdi-check me-1" v-if="viewMode === 'default'"></i>
                <i class="mdi mdi-checkbox-blank-circle-outline me-1"  v-if="viewMode !== 'default'"></i>
                Default
              </a>
            </li>
            <li>
              <a class="dropdown-item" :class="{'active': viewMode === 'extended'}" href="javascript:void(0)" @click="setStateViewMode({stateId: state.id, mode: 'extended'})">
                <i class="mdi mdi-check me-1" v-if="viewMode === 'extended'"></i>
                <i class="mdi mdi-checkbox-blank-circle-outline me-1"  v-if="viewMode !== 'extended'"></i>
                Extended
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    
    <!-- <div v-if="keywordEnabled && !collapsed" class="mt-2" style="position: relative;">
      <input type="text" class="form-control  form-control-sm" style="padding-left:25px" :placeholder="$t('common.search')"/>
      <span class="text-muted" style="position:absolute; top:5px; left:7px" >
        <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
      </span>
    </div> -->
  </th>
</template>
